import { useAuth } from "hooks/useAuth";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Dashboard } from "./dashboard";
import { Login } from "./login";
import { PageLoading } from "./PageLoading";

export function Routes() {
  const { user, loading } = useAuth();

  if (loading) {
    return <PageLoading />;
  }

  if (user) {
    return (
      <Router>
        <Switch>
          <Route path="/" component={Dashboard} />
        </Switch>
      </Router>
    );
  } else {
    return (
      <Router>
        <Switch>
          <Route path="/" component={Login} />
        </Switch>
      </Router>
    );
  }
}

const firebaseConfig = {
  apiKey: "AIzaSyCDL_Zx79fwF7Ju-9g6koPki91YZhVzYjo",
  authDomain: "triumph-stats.firebaseapp.com",
  projectId: "triumph-stats",
  storageBucket: "triumph-stats.appspot.com",
  messagingSenderId: "383039987730",
  appId: "1:383039987730:web:2e694b77215652e44338cb",
  measurementId: "G-QYPGH6D0ZC",
};

export default firebaseConfig;

import { reverse, sortBy } from "lodash";
import { signOut } from "services/firebase/auth";
import { useDocument } from "react-firebase-hooks/firestore";
import { db } from "services/firebase";
import { PageLoading } from "routes/PageLoading";

interface Person {
  name: string;
  value: number;
}

interface UserStat {
  name: string;
  daily: {
    opportunties: number;
    calls: number;
  };
  monthly: {
    opportunties: number;
    calls: number;
  };
}

export function Dashboard() {
  const [document, loading] = useDocument<UserStat[]>(db.collection("triumph").doc("stats"));
  if (loading || !document) {
    return <PageLoading />;
  } else {
    const data = document.data();
    const stats = Object.entries(data || {}).map((key) => ({ ...key[1] }));
    const topCallingDaily = reverse(
      sortBy(
        stats.map((stat) => ({
          name: stat.name,
          value: stat.daily.calls,
        })),
        "value"
      )
    );
    const topCallingMonthly = reverse(
      sortBy(
        stats.map((stat) => ({
          name: stat.name,
          value: stat.monthly.calls,
        })),
        "value"
      )
    );
    const topAppsDaily = reverse(
      sortBy(
        stats.map((stat) => ({
          name: stat.name,
          value: stat.daily.opportunties,
        })),
        "value"
      )
    );
    const topAppsMonthly = reverse(
      sortBy(
        stats.map((stat) => ({
          name: stat.name,
          value: stat.monthly.opportunties,
        })),
        "value"
      )
    );

    return (
      <div className="mt-6 text-white max-w-7xl mx-auto rounded py-8 px-4">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-8">
          <StatSection
            headerTitle="Calls Today"
            people={topCallingDaily}
            tableHeader="Calls"
          />
          <StatSection
            headerTitle="Apps Today"
            people={topAppsDaily}
            tableHeader="Apps"
          />
          <StatSection
            headerTitle={`${new Date().toLocaleString("en-US", { month: "long" })} Calls`}
            people={topCallingMonthly}
            tableHeader="Calls"
          />
          <StatSection
            headerTitle={`${new Date().toLocaleString("en-US", { month: "long" })} Apps`}
            people={topAppsMonthly}
            tableHeader="Apps"
          />
        </div>
        <div className="flex justify-around sm:justify-end text-gray-500 text-sm hover:text-gray-400 mt-8 pb-8">
          <button onClick={() => signOut()}>Sign out</button>
        </div>
      </div>
    );
  }
}

interface StatSectionProps {
  headerTitle: string;
  tableHeader: string;
  people: Person[];
}

function StatSection({ headerTitle, tableHeader, people }: StatSectionProps) {
  const topPerson = people[0];
  return (
    <div className="p-2">
      <div>
        <h3 className="text-green-500 uppercase text-xs sm:text-sm font-semibold text-center mb-1">{headerTitle}</h3>
        <p className="text-2xl sm:text-3xl lg:text-4xl font-bold font-marker capitalize text-center">
          {topPerson ? topPerson.name : "😵"}
        </p>
      </div>
      <div className="mt-6 sm:mt-12">
        <Table valueTitle={tableHeader} people={reverse(sortBy(people, "value"))} />
      </div>
    </div>
  );
}

interface TableProps {
  valueTitle: string;
  people: Person[];
}

function Table({ valueTitle, people }: TableProps) {
  function renderTrophy(index: number) {
    switch (index) {
      case 0:
        return <span className="mr-2">🥇</span>;
      case 1:
        return <span className="mr-2">🥈</span>;
      case 2:
        return <span className="mr-2">🥉</span>;
      default:
        return;
    }
  }
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden rounded-md border border-gray-600 max-h-80 overflow-y-scroll">
            <table className="min-w-full divide-y divide-gray-200 relative">
              <thead className="bg-gray-700">
                <tr className="border-b border-gray-600">
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 bg-gray-700 uppercase tracking-wider sticky top-0"
                  >
                    Top 5
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium text-gray-400 bg-gray-700 uppercase tracking-wider text-right sticky top-0"
                  >
                    {valueTitle}
                  </th>
                </tr>
              </thead>
              <tbody>
                {people.length === 0 ? (
                  <tr className="bg-gray-700 border-t border-gray-600">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400">No one yet...</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-white text-right">😠</td>
                  </tr>
                ) : (
                  people.map((person, personIdx) => (
                    <tr key={personIdx} className="bg-gray-700 border-t border-gray-600">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white capitalize font-medium">
                        {renderTrophy(personIdx)}
                        {person.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white text-right font-medium">
                        {person.value.toLocaleString()}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

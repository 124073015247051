import { auth } from "./firebase";

interface SignInErrorInterface {
  /** Will be true if there was a problem logging in. */
  error: boolean;

  /** The error message. */
  message: string;
}

interface FirebaseSignInError {
  /** Possible error codes from Firebase auth.
   * https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signinwithemailandpassword
   */
  code:
    | "auth/invalid-email"
    | "auth/user-disabled"
    | "auth/user-not-found"
    | "auth/wrong-password"
    | "auth/internal-error";

  /** Firebase error message. */
  message: string;
}

// Sign in
export async function doSignInWithEmailAndPassword(email: string, password: string): Promise<SignInErrorInterface> {
  try {
    const success = await auth.signInWithEmailAndPassword(email, password);
    if (success) {
      return { error: false, message: "" };
    } else {
      return { error: true, message: "Something went wrong..." };
    }
  } catch (error) {
    const { code }: FirebaseSignInError = error;
    let message = "";
    switch (code) {
      case "auth/invalid-email":
        message = "The email address is not valid.";
        break;
      case "auth/internal-error":
        message = "Something went wrong...";
        break;
      case "auth/user-disabled":
        message = "This account has been disabled.";
        break;
      case "auth/user-not-found":
        message = "This account could not be found.";
        break;
      case "auth/wrong-password":
        message = "That password is incorrect.";
    }
    return { error: true, message };
  }
}

export async function signOut() {
  try {
    await auth.signOut();
  } catch (error) {
    console.log("Could not be signed out.", error);
  }
}

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import config from "./config";
firebase.initializeApp(config);

export const app = firebase;
export const auth = firebase.auth();
export const db = firebase.firestore();
export const functions = firebase.functions();

// Use a local emulator when developing...
declare const location: Location;
if (location.hostname === "localhost") {
  firebase.functions().useEmulator("localhost", 5000);
  //   db.settings({
  //     host: "localhost:8080",
  //     ssl: false,
  //     experimentalForceLongPolling: true,
  //   });
}

export async function register(email: string, password: string) {
  try {
    const user = await auth.createUserWithEmailAndPassword(email, password);
    if (!user.user) throw new Error("Unable to create user.");
    return { success: true, message: "Successfully created a new user.", user: user.user };
  } catch (error) {
    return { success: false, message: error, user: null };
  }
}

export async function resetPassword(email: string) {
  try {
    await auth.sendPasswordResetEmail(email);
  } catch (error) {
    const { code }: firebase.FirebaseError = error;
    switch (code) {
      case "auth/invalid-email":
        throw new Error("The email address is not valid.");
      case "auth/user-disabled":
        throw new Error("This account has been disabled.");
      case "auth/user-not-found":
        throw new Error("This account could not be found.");
      case "auth/wrong-password":
        throw new Error("That password is incorrect.");
    }
  }
}

import React, { useState } from "react";
import { doSignInWithEmailAndPassword } from "services/firebase/auth";

export function Login() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>();

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    signInToFirebase();
  }

  async function signInToFirebase() {
    try {
      const response = await doSignInWithEmailAndPassword(email, password);
      console.log(response);
      if (response.error) setError(response.message);
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  }

  return (
    <div className="max-w-md mx-auto">
      <div className="mt-6 mb-2">
        {error && <div className="mb-2 text-red-500">{error}</div>}
        <h1 className="text-lg font-semibold text-white">For internal use only!</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col space-y-4">
          <input
            type="text"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            placeholder="Email Address"
            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-400"
          />
          <input
            type="password"
            name="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            placeholder="Password"
            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-400"
          />
          <button
            type="submit"
            className="inline-flex items-center justify-around w-full px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Sign In
          </button>
        </div>
      </form>
    </div>
  );
}

import { useState, useEffect } from "react";
import { auth, db } from "services/firebase";

const loggingEnabled = false;

interface ReturnProps {
  user?: firebase.default.User | null;
  loading: boolean;
}

export function useAuth(): ReturnProps {
  let localUser = localStorage.getItem("triumphStats");
  const [user, setUser] = useState<firebase.default.User | null>((localUser && JSON.parse(localUser)) || null);

  useEffect(() => {
    // listen for auth state changes
    if (loggingEnabled) console.log("useEffect - Firebase authUser");
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
        localStorage.setItem("triumphStats", JSON.stringify(authUser));
        db.collection("users").doc(authUser.uid).set({ email: authUser.email }, { merge: true });
      } else {
        setUser(null);
        localStorage.removeItem("triumphStats");
      }
    });

    // unsubscribe to the listener when unmounting
    return () => unsubscribe();
  }, [user]);

  if (!user) return { loading: false };
  if (user) return { user, loading: false };
  return { loading: true };
}
